@use "./variables.scss";
@use "~react-big-calendar/lib/sass/styles.scss";

.rbc-date-cell {
  text-align: center;
}

.rbc-current-time-indicator {
  background-color: #d92d20 !important;

  &::before {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #d92d20;
    position: absolute;
    top: -6px;
    left: -6px;
  }
}

.rbc-allday-cell {
  display: none !important;
}

.rbc-event {
  position: relative;
  &--hosting {
    &::before {
      content: "";
      width: 3px;
      height: 100%;
      background: #fc803d;
      position: absolute;
      top: 0;
      left: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
  &--attending {
    &::before {
      content: "";
      width: 3px;
      height: 100%;
      background: #06aed4;
      position: absolute;
      top: 0;
      left: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
}

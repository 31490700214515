@tailwind base;
@tailwind components;
@tailwind utilities;

.Toastify__toast {
  border-radius: 8px !important;
}

.Toastify__toast--error {
  background: #fffbfa !important;
}

.Toastify__toast-icon {
  display: none !important;
}

/* react-datepicker */

.react-datepicker {
  @apply !border !border-dgray-100 !shadow-lg !p-2;
}

.react-datepicker__header {
  @apply !bg-white !border-none !font-medium !text-dgray-700;
}

.react-datepicker__current-month {
  @apply !font-medium !text-dgray-700;
}

.react-datepicker__day-name {
  @apply !w-10 !text-dgray-700 !mt-4;
}

.react-datepicker__day {
  @apply !h-10 !w-10 !pt-1.5 !text-dgray-700;
}

.react-datepicker__day--outside-month {
  @apply !text-dgray-500;
}

.react-datepicker__day--today {
  @apply !text-primary-600;
}

.react-datepicker__day--selected {
  @apply !border-2 !border-primary-600 !bg-white !rounded-full;
}

.react-datepicker__day--session-highlight {
  @apply !bg-primary-600 !text-white;
}

.react-datepicker__day--selected.react-datepicker__day--session-highlight {
  @apply !ring;
}

.react-datepicker__day--session-highlight:not(
    .react-datepicker__day--selected
  ) {
  @apply !font-medium !rounded-full;
}

.react-datepicker__day--point-highlight {
  position: relative;
}

.react-datepicker__day--point-highlight::after {
  position: absolute;
  content: '';
  bottom: 5px;
  left: 50%;
  margin-left: -2px;
  width: 5px;
  height: 5px;
  @apply !bg-primary-600 rounded-full;
}


.react-datepicker__day--keyboard-selected {
  @apply !bg-white !text-dgray-700 !ring-2 !ring-primary-300 !rounded-full;
}

.react-datepicker__day--disabled,
.react-datepicker__day--excluded {
  @apply !opacity-20;
}

.react-datepicker-time__header {
  @apply !hidden;
}

.react-datepicker__time-list-item {
  @apply !text-dgray-500 !rounded;
}
.react-datepicker__time-list-item--selected {
  @apply !text-white !bg-primary-600;
}

.react-datepicker__navigation,
.react-datepicker__navigation::before,
.react-datepicker__navigation::after,
.react-datepicker__navigation-icon {
  @apply !top-2 !text-blue-700 !border-green-500;
}

.rec-item-wrapper, .rec-swipable {
  height: 100% !important;
}
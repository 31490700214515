.sanitized-content {
  h2 {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 12px;
    @apply text-dgray-700;
  }
  h3 {
    font-weight: 600;
    font-size: 20px;
    @apply text-dgray-700 mb-2;
  }
  h4 {
    font-weight: 500;
    font-size: 18px;
    @apply text-dgray-700 mb-2;
  }
  a {
    text-decoration: underline;
  }
  p {
    overflow-wrap: break-word;
  }
  ul,
  ol {
    padding-left: 32px;
  }
  ol {
    list-style: decimal;
  }
  ul {
    list-style: disc;
  }
}

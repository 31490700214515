.ReactTags__tags {
    padding: 0.625rem 0.875rem;
    border: 1px solid #D0D5DD;
    border-radius: 0.5rem;
    .ReactTags__tag {
        border-radius: 0.5rem;
        border: 1px solid #D0D5DD;
        margin-right: 0.25rem;
        font-size: 14px;
        padding: 4px 0 4px 10px;
        line-height: 30px;
    }
    .ReactTags__remove {
        color: #D0D5DD;
        margin: 0 10px;
    }
    .ReactTags__tagInputField {
        width: 100%;
        &:focus {
            outline: none;
        }
    }
}
// If you want to override variables do it here
// @import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
// @import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
// @import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
//@import '~simple-line-icons/css/simple-line-icons.css';

// @import "variables";

// Import styles
// @import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
// @import "~@coreui/coreui/scss/_dropdown-menu-right.scss";

// If you want to add something do it here
// @import "custom";
//@import "front-custom";

// ie fixes
// @import "ie-fix";

.coreui-admin {
  @import "~@coreui/coreui/scss/coreui.scss";
  .sidebar {
    .nav, .sidebar-nav {
      width: 230px;
    }
  }
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
  @apply rounded-t-lg;
}
.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable {
  @apply rounded-b-lg;
}

.ck {
  .ck-content {
    .ck-editor__editable {
      background: red;
    }
    .ck-blurred {
    }
  }
}

.ck.ck-toolbar.ck-toolbar_grouping {
  border: 1px solid #d0d5dd !important;
  border-bottom: none !important;
}

.ck-editor__editable.ck-focused,
.ck-editor__editable.ck-blurred {
  box-shadow: none !important;
  border: 1px solid #d0d5dd !important;
}

.ck {
  .ck-toolbar {
    background: white;
  }

  // .ck-toolbar_grouping {
  // }

  .ck-content {
    color: #667085;
    hr {
    }
    h2 {
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 12px;
    }
    h3 {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 8px;
    }
    h4 {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 8px;
    }
    a {
      text-decoration: underline;
    }
    p {
    }
    blockquote.side-quote {
    }
    blockquote.side-quote::before {
    }
    blockquote.side-quote p {
    }
    blockquote.side-quote p:last-child:not(:first-child) {
    }
    span.marker {
    }
    span.spoiler {
    }
    span.spoiler:hover {
    }
    ul,
    ol {
      padding-left: 32px;
    }
    ol {
      list-style: decimal;
    }
    ul {
      list-style: disc;
    }
  }
  .ck-horizontal-line {
  }
}
